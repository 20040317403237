<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('REGISTER_RESELLER.PROFILE_PERCENTAGE_PROFILE') }}
    </p>
    <section class="content-margin">
      <div class="d-flex align-items-center m-b-30 px15 filter-block">
        <label>{{ $t('MANAGE_PRODUCT.SELECT_CATEGORY') }}</label>
        <vue-select
          :options="profile.options"
          class="input cus-select profile-dropdown"
          v-model="profile.selected"
          @input="changeProfile"
        ></vue-select>
        <button class="primary-btn btn-limit-width bg-yellow" @click="openNewProfileModal">
          {{ $t('MANAGEMENT.NEW_PROFILE') }}
        </button>
      </div>

      <div class="m-b-40 px15" v-if="selectedProfile">
        <label>{{ $t('MANAGEMENT.APPROVED_FOR_USE') }}</label>
        <vue-select
          :options="roles.options"
          class="input cus-select cus-multi-dropdown"
          v-model="roles.selected"
          multiple
        ></vue-select>
      </div>

      <div class="px15 m-b-30" v-if="selectedProfile">
        <div class="fee-block d-flex align-items-center flex-wrap">
          <div class="d-flex align-items-center">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="use_distribution_fee" id="ditribution_fee"></v-checkbox>
            <label class="mx10" for="ditribution_fee">{{ $t('MANAGEMENT.USING_DISTRIBUTION_FEE') }}</label>
          </div>
          <div class="d-flex align-items-center">
            <v-checkbox color="#0D3856" class="cus-checkbox default-profile" v-model="is_default" id="is_default"></v-checkbox>
            <label class="mx10" for="is_default">{{ $t('MANAGEMENT.AS_DEFAULT_PROFILE') }}</label>
          </div>
          <div class="d-flex align-items-center">
            <v-checkbox color="#0D3856" class="cus-checkbox default-profile" v-model="is_shaniv_default" id="is_shaniv_default"></v-checkbox>
            <label class="mx10" for="is_shaniv_default">{{ $t('MANAGEMENT.AS_SHANIV_DEFAULT_PROFILE') }}</label>
          </div>

          <button class="primary-btn primary-btn_sm mx30" @click="saveProfileFlags">
            {{ $t('COMMON.SAVE') }}
          </button>
        </div>
      </div>

      <div class="d-flex flex-wrap" v-if="selectedProfile">
        <div class="w-25 form-div px15 m-b-20">
          <label>{{ $t('MANAGE_PRODUCT.SELECT_PROVIDER') }}</label>
          <vue-select
            :options="suppliersList.options"
            class="input cus-select"
            v-model="suppliersList.selected"
            @input="changeSuppliersList"
          ></vue-select>
        </div>
      </div>

      <!-- <div class="w-75 px15 m-t-50">
        <vue-slider 
          v-model="sliderValue"
          :direction="sliderDirection"
          class="cus-vue-slider"
          tooltip="always"
          :min="0"
          :max="100"
        ></vue-slider>
      </div> -->

      <div class="d-flex m-t-50 flex-wrap" v-if="selectedProfile">
        <div class="w-25 px15">
          <label>{{ $t('MANAGEMENT.PROFIT_PRO') }}</label>
          <input type="text" class="input" v-model="productProfit">
        </div>
        <div class="w-50 px15">
          <label></label>
          <button class="primary-btn"  @click="applyProfit" v-if="productProfit.length">
            {{ $t('MANAGEMENT.APPLY_PROFIT_ON_ALL_PRODUCTS') }}
          </button>
          <button class="primary-btn" v-else disabled>
            {{ $t('MANAGEMENT.APPLY_PROFIT_ON_ALL_PRODUCTS') }}
          </button>
        </div>
      </div>

      <div class="d-flex m-t-20 flex-wrap" v-if="selectedProfile">
        <div class="w-25 px15">
          <label>{{ $t('MANAGEMENT.POINTS') }}</label>
          <input type="text" class="input" v-model="productPoint">
        </div>
        <div class="w-50 px15">
          <label></label>
          <button class="primary-btn" @click="applyPoint" v-if="productPoint.length">
            {{ $t('MANAGEMENT.APPLY_GIFT_POINTS_TO_ALL_PRODUCTS') }}
          </button>
          <button class="primary-btn" v-else disabled>
            {{ $t('MANAGEMENT.APPLY_GIFT_POINTS_TO_ALL_PRODUCTS') }}
          </button>
        </div>
      </div>
    </section>

    <section class="table-content-part m-t-40" v-if="selectedProfile">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('COMMON.SEARCH') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="_headers"
        :search="search"
        :items="tableData"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
      >
        <template v-for="h in _headers" v-slot:[`header.${h.value}`]="{ }">
          <div :key="h.value" class="d-flex align-items-center flex-column">
            <span>{{h.text}}</span>
            <v-checkbox 
              color="#0D3856" 
              class="cus-checkbox" 
              v-if="h.value=='distribution_fee'"
              v-model="a_distributionAll"
            ></v-checkbox>
            <v-checkbox 
              color="#0D3856" 
              class="cus-checkbox" 
              v-else-if="h.value=='is_authorized'"
              v-model="enabledAll"
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.supplier_id`]="{ item }">
          <div style="display: flex; justify-content: center;">
            {{ item.Product.supplier_id }}
          </div>
        </template>
        <template v-slot:[`item.product_name`]="{ item }">
          <div style="display: flex; justify-content: center;">
            {{ item.Product.ProductDetails && item.Product.ProductDetails[0] ? item.Product.ProductDetails[0].name : null }}
          </div>
        </template>
        <template v-slot:[`item.type_of_product`]="{ item }">
          <div style="display: flex; justify-content: center;">
            {{ item.Product.type }}
          </div>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <div style="display: flex; justify-content: center;">
            {{ item.Product.ProductPrice && item.Product.ProductPrice[0] ? item.Product.ProductPrice[0].fixed_price : null }}
          </div>
        </template>
        <template v-slot:[`item.points`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input type="text" class="input" v-model="item.points">
          </div>
        </template>
        <template v-slot:[`item.percentage_profit`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <input type="text" class="input" v-model="item.percentage_profit">
          </div>
        </template>
        <template v-slot:[`item.distribution_fee`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.distribution_fee"></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.is_authorized`]="{ item }">
          <div style="display: flex; justify-content: center;">
            <v-checkbox color="#0D3856" class="cus-checkbox" v-model="item.is_authorized"></v-checkbox>
          </div>
        </template>
      </v-data-table>
    </section>

    <div class="content-margin m-b-40 d-flex m-t-40" v-if="selectedProfile">
      <button class="primary-btn btn-limit-width" @click="updateProfile">
        {{ $t("COMMON.SAVE") }}
      </button>
      <button class="primary-btn btn-limit-width bg-red1 mx20" @click="openDeleteConfirm">
        {{ $t("COMMON.DELETE") }}
      </button>
    </div>

    <modal
      :width="550"
      :adaptive="true"
      class="newProfileModal CEModal modal-scroll-bar"
      name="newProfileModal"
    >
      <div class="modal-content">
        <div class="validation-form-group"
          :class="{ 'form-group--error': $v.newProfile.$error }">
          <label>{{ $t('MANAGEMENT.ROLE_NAME') }} *</label>
          <input
            type="text" 
            class="input"
            v-model="newProfile"
            @input="$v.newProfile.$touch"
          />
          <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newProfile.required">
            {{ $t('VALIDATION.REQUIRED_FIELD') }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="primary-btn primary-btn_sm mx10" @click="saveNewProfile">
          {{ $t('DESTINATION.CONFIRM') }}
        </button>
        <button class="primary-btn primary-btn_sm mx10 bg-2" @click="closeModal">
          {{ $t('COMMON.CANCEL') }}
        </button>
      </div>
    </modal>
    <ConfirmModal @confirmData="confirmData">
      <template v-slot:modal-content v-if="isDeleteConfirm">
        {{ $t('ALERT.DELETE_CONFIRM_TXT', {NAME: selectedProfile ? selectedProfile.name : ""}) }}
      </template>
      <template v-slot:modal-content v-else>
        {{ $t('MANAGEMENT.PRODUCT_ACTION_WARNING_TXT') }}
      </template>
    </ConfirmModal>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>  
  </main> 
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ConfirmModal from "../../../components/management/ConfirmModal.vue"
export default {
  name: 'CPProductsProfiles',
  mixins: [validationMixin],
  components: {
    ConfirmModal
  },
  data() {
    return {
      profile: {
        options: [],
        selected: ""
      }, 
      roles: {
        options: [],
        selected: []
      }, 
      newProfile: "",
      suppliersList: {
        options: [],
        selected: ""
      },
      tableData: [],
      search: '',
      isUseDistributionFee: false,
      use_distribution_fee: false,
      is_default: false,
      is_shaniv_default: false,
      productProfit: "",
      productPoint: "",
      isDeleteConfirm: false
      // sliderValue: [0, 50]
    }
  },
  validations: {
    newProfile: {
      required
    }
  },
  computed: {
    ...mapState({
      profitProfile: (state) => state.category_products.profitProfile,
      profitProfileData: (state) => state.category_products.profitProfileData,
      rolesList: (state) => state.seller_tourism_percentage.rolesList,
      supplier_items: (state) => state.supplier.supplier_items,
      loading1: (state) => state.seller_tourism_percentage.loading,
      loading2: (state) => state.supplier.loading,
    }),
    loading() {
      return this.loading1 || this.loading2;
    },
    a_distributionAll: {
      get: function () {
        const checkedLength = this.tableData.filter(function(item) {
          return item.distribution_fee!=null && item.distribution_fee!=false;
        }).length;
        if (checkedLength == this.tableData.length) {
          return true
        } 
        return false;
      },
      set: function (value) {
        this.tableData.forEach(item => {
          item.distribution_fee = value;
        })
      }
    },
    enabledAll: {
      get: function () {
        const checkedLength = this.tableData.filter(function(item) {
          return item.is_authorized==false;
        }).length;
        if (checkedLength) {
          return false
        } 
        return true;
      },
      set: function (value) {
        this.tableData.forEach(item => {
          item.is_authorized = value;
        })
      }
    },
    _headers () {
      let headerArray = [];
      const headerFront = [
        {
          text: this.$t('REPORT_SALES.SUPPLIER_ID'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('DASHBOARD.PRODUCT_NAME'),
          align: 'center',
          sortable: false,
          value: 'product_name',
        },
        {
          text: this.$t('MANAGEMENT.TYPE_OF_PRODUCT'),
          align: 'center',
          sortable: false,
          value: 'type_of_product',
        },
        {
          text: this.$t('COMMON.PRICE'),
          align: 'center',
          sortable: false,
          value: 'price',
        },
        {
          text: this.$t('MANAGEMENT.PERCENTAGE_PROFIT'),
          align: 'center',
          sortable: false,
          value: 'percentage_profit',
        },
        {
          text: this.$t('MANAGEMENT.POINTS'),
          align: 'center',
          sortable: false,
          value: 'points',
        },
      ]

      const headerBack = [
        {
          text: this.$t('MANAGEMENT.ENABLED'),
          align: 'center',
          sortable: false,
          value: 'is_authorized'
        }
      ]
      const headerDistribution = [
        {
          text: this.$t('MANAGEMENT.A_DISTRIBUTION'),
          align: 'center',
          sortable: false,
          value: 'distribution_fee'
        },
      ]

      if (this.isUseDistributionFee) {
        headerArray = [...headerFront, ...headerDistribution, ...headerBack];
      } else {
        headerArray = [...headerFront, ...headerBack];
      }
      return headerArray;
    },
    selectedProfile() {
      let selectedProfile = null;

      if (this.profitProfileData && this.profitProfileData.length > 0
        && this.profile && this.profile.selected && this.profile.selected.code === this.profitProfileData[0].id) {
        selectedProfile = this.profitProfileData[0];
      }

      return selectedProfile;
    }
  },
  async created() {
    if (this.profitProfile.length == 0) {
      await this.getProfitProfile();
    }
    if (this.profitProfile.length != 0) {
      await this.createProfileDropdown();
    }
    if (this.rolesList.length == 0) {
      await this.getRoles();
    }
    if (this.rolesList.length != 0) {
      this.roles = {
        options: [],
        selected: ""
      };
      this.rolesList.forEach(el => {
        this.roles.options.push({
          label: el.name,
          code: el.id,
        });
      });

      this.roles.selected = [];
    }

    if (Object.keys(this.supplier_items).length == 0) {
      await this.getAllSupplierItems();
    }
    if (Object.keys(this.supplier_items).length != 0) {
      for (const key in this.supplier_items) {
        this.supplier_items[key].forEach(el => {
          this.suppliersList.options.push({
            label: el.name,
            code: el.id,
          });
        });
      }
    }

    this.changeProfile();
  },
  methods: {
    ...mapActions("category_products", {
      getProfitProfile: "getProfitProfile",
      getProfitProfileData: "getProfitProfileData",
      createProfitProfile: "createProfitProfile",
      updateProductProfit: "updateProductProfit",
      updateProfitProfile: "updateProfitProfile",
      deleteCommissionProfile: "deleteCommissionProfile"
    }),
    ...mapActions("seller_tourism_percentage", {
      getRoles: "getRoles",
      updateProfileFlags: "updateProfileFlags"
    }),
    ...mapActions("supplier", {
      getAllSupplierItems: "getAllSupplierItems"
    }),
    async clearForm() {
      this.roles.selected = [];
      this.tableData = [];
      this.use_distribution_fee = false;
      this.isUseDistributionFee = false;
      this.is_default = false;
      this.is_shaniv_default = false;
      this.search = "";
      this.productProfit = "";
      this.productPoint = "";
      this.suppliersList.selected = "";
    },
    async createProfileDropdown(id = null) {
      this.profile = {
        options: [],
        selected: ""
      };
      this.profitProfile.forEach(el => {
        this.profile.options.push({
          label: el.name,
          code: el.id,
        });
      });

      if (this.profile.options && this.profile.options.length > 0) {
        let profileToSelect = this.profile.options[0];
        if (id) {
          profileToSelect = this.profile.options.find(p => p.code === id) || profileToSelect;
        }
        this.profile.selected = {...(profileToSelect)};
        this.profile = {...(this.profile)};
      }
      await this.changeProfile();
    },
    async changeProfile() {
      await this.clearForm();
      if (!this.profile || !this.profile.selected || !this.profile.selected.code) {
        return;
      }
      let profile_id = this.profile.selected.code;
      await this.getProfitProfileData({
        id: profile_id
      });
      if (this.selectedProfile) {
        if (this.selectedProfile.Roles && this.selectedProfile.Roles.length > 0) {
          this.selectedProfile.Roles.forEach(el => {
            this.roles.selected.push({
              code: el.BusinessRole.role_id,
              label: el.name
            });
          });
        }
        this.tableData = this.selectedProfile.BusinessProduct;
        this.is_default = this.selectedProfile.is_default;
        this.is_shaniv_default = this.selectedProfile.is_shaniv_default;
        if (this.selectedProfile.use_distribution_fee) {
          this.use_distribution_fee = true;
          this.isUseDistributionFee = true;
        }
      }
    },
    openNewProfileModal() {
      this.$modal.show('newProfileModal');
    },
    closeModal() {
      this.$modal.hide('newProfileModal');
      this.newProfile = "";
    },
    saveNewProfile() {
      this.$v.$touch();
      if (
        this.$v.$anyError
      ) {
        return;
      }
      this.createProfitProfile({
        name: this.newProfile,
        profitModle: "profit"
      }).then(async res => {
        this.closeModal();
        if (res && res.message == "success") {
          let createdProfileId = res && res.id ? res.id : null;
          await this.getProfitProfile();
          await this.createProfileDropdown(createdProfileId);
        }
      })
    },
    changeSuppliersList() {
      this.tableData = [];
      const supplier_id = this.suppliersList && this.suppliersList.selected
        ? this.suppliersList.selected.code : null;
      if (this.selectedProfile && this.selectedProfile.BusinessProduct) {
        this.tableData = this.selectedProfile.BusinessProduct.filter((el) => {
          return el.Product.supplier_id == supplier_id;
        });
      }
    },
    async saveProfileFlags() {
      if (!this.selectedProfile) {
        return;
      }
      const profile_id = this.selectedProfile.id;
      const params = {
        business_id: profile_id,
        use_distribution_fee: !!this.use_distribution_fee,
        is_default: !!this.is_default,
        is_shaniv_default: !!this.is_shaniv_default
      };
      await this.updateProfileFlags({params: params});

      this.changeProfile();
    },
    applyProfit() {
      this.isProfitConfirm = true;
      this.isDeleteConfirm = false;
      this.$modal.show('confirmModal');
    },
    applyPoint() {
      this.isProfitConfirm = false;
      this.isDeleteConfirm = false;
      this.$modal.show('confirmModal');
    },
    async confirmData() {
      if (!this.selectedProfile) {
        return;
      }
      const profile_id = this.selectedProfile.id;
       if (this.isDeleteConfirm) {
        await this.deleteCommissionProfile(profile_id);
        await this.getProfitProfile();
        await this.createProfileDropdown();
      } else {
        let product_id = [];
        this.tableData.forEach((el) => {
          product_id.push(el.product_id);
        });
        let supplier_id = null;
        if (this.suppliersList.selected != "") {
          supplier_id = this.suppliersList.selected.code;
        }
        let params = {};
        if (this.isProfitConfirm) {
          params = {
            percentage_profit: this.productProfit,
            product_id: product_id,
            profile_id: profile_id,
            supplier_id: supplier_id,
            type: "profit"
          }
        } else {
          params = {
            points: this.productPoint,
            product_id: product_id,
            profile_id: profile_id,
            supplier_id: supplier_id,
            type: "profit"
          }
        }
        
        await this.updateProductProfit(params);
        this.productProfit = "";
        this.productPoint = "";
        this.changeProfile();
      }
      this.$modal.hide('confirmModal');
    },
    async updateProfile() {
      if (!this.selectedProfile) {
        return;
      }
      const profile_id = this.selectedProfile.id;
      let tempRoles = [];
      this.roles.selected.forEach(el => {
        tempRoles.push(el.label);
      });
      let tempProducts_list = [];
      if (this.selectedProfile && this.selectedProfile.BusinessProduct) {
        this.selectedProfile.BusinessProduct.forEach(el => {
          tempProducts_list.push({
            is_authorized: el.is_authorized,
            percentage_profit: el.percentage_profit,
            points: el.points,
            product_id: el.product_id,
          });
        });
      }
      let params = {
        products_list: tempProducts_list,
        profile_id: profile_id,
        roles: tempRoles,
        is_default: !!this.is_default,
        is_shaniv_default: !!this.is_shaniv_default
      }
      await this.updateProfitProfile(params);
      this.changeProfile();
    },
    openDeleteConfirm() {
      if (!this.selectedProfile) {
        return;
      }
      this.isDeleteConfirm = true;
      this.$modal.show('confirmModal');
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  height: 27px;
  display: block;
}

.fee-block {
  background: #E5E5E5;
  border: 1px solid #D4D4D4;
  padding: 10px;
  label {
    height: unset;
  }
}

.newProfileModal {
  .modal-footer {
    padding: 0 40px 30px;
  }
}

@media screen and (max-width: 635px) {
  .filter-block {
    flex-wrap: wrap;
    button {
      margin-top: 10px;
    }
  }
  .primary-btn {
    height: unset;
    min-height: 44px;
  }
  .w-25,
  .w-50,
  .w-75 {
    width: 100% !important;
  }
}
</style>